.ColorPallete {
    display: flex;
    width: 100%;
    overflow-y: scroll;
}

.ColorPallete-background {
    background-color: #F4F2EF;
    width: 100%;
    /* height: fit-content; */
}

.ColorPallete-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.colorpallete-elem {
    margin-top: 8%;
    margin-left: 9%;
}

.colorpallete-sec {
    margin-top: 7%;
}

.colorpallete-sec p{
    margin-top: 0;
}

.primary-color-container {
    display: flex;
    flex-wrap: wrap;
}

.primary-color-text{
    display: grid;
    margin-right: 29px;
}

.primary-color-text p {
    color: #1F3C3D;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    margin-top: 6px;
}

.primary-square {
    width: 100px;
    height: 90px;
    border-radius: 10px;
}

.secondary-color-container-1 {
    display: flex;
    flex-wrap: wrap;
}

.secondary-color-container-2 {
    display: flex;
    flex-wrap: wrap;
}

.secondary-color-text{
    display: grid;
    margin-right: 29px;
}

.secondary-color-text p {
    color: #1F3C3D;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    margin-top: 6px;
}

.secondary-square {
    width: 80px;
    height: 60px;
    border-radius: 6px;
}


@media only screen and (max-width: 1000px) {
    .ColorPallete-background {
        margin-left: 0;
        margin-top: 50px;
    }

}