.FloatingActionButton {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.floatingactionbutton-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.floatingactionbutton-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.floatingactionbutton-elem {
    margin-left: 5%;
    margin-top: 3%;
    margin-right: 6%;
}

.floatingactionbutton-container-1 {
    width: auto;
    height: fit-content;
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.floatingactionbutton-container-1 p {
    margin-top: 0;
    margin-bottom: 2%;
}

.floatingactionbutton-container-3 {
    width: auto;
    height: fit-content;
    margin-bottom: 138px;
}

.floatingactionbutton-container-2 {
    margin-top: 26px;
    margin-bottom: 41px;
    height: fit-content;
}

.first p {
    font-family: 'Open Sans', sans-serif;
    color: #393A3C;
}

.first h5 {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
}

.container-2-middle {
    display: flex;
    flex-wrap: wrap;
}

.fab-text p {
    color: #000000;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-top: 0;
}

.fab-img {
    width: 248px;
    height: 111px;
    background-color: #EAE8E5;
    margin-left: 34px;
}

.fab-img {
    display: flex;
    justify-content: space-between;
}

.fab-img img:last-child {
    margin-top: 36px;
    margin-right: 49px;
}

.img-2 {
    z-index: 0;
    margin-top: 28px;
    margin-left: 39px;
}

.img-1 {
    position: absolute;
    margin-top: 47px;
    margin-left: 58px;
    z-index: 1;
}

@media only screen and (max-width: 1000px) {
    .floatingactionbutton-background {
        margin-left: 0;
    }

}