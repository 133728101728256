.Spacing {
    display: flex;
    width: 100%;
}

.spacing-background {
    background-color: #F4F2EF;
    width: 100%;
    height: 100%;
}

.spacing-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.spacing-elem {
    min-height: 677px;
    height: fit-content;
    display: flex;
    margin-top: 3%;
    margin-right: 5%;
    margin-left: 9%;
    margin-bottom: 5%;
    flex-wrap: wrap;
}

.spacing-content {
    width: 40%;
    height: auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    margin-right: 0px;
    margin-top: 35px;
    margin-bottom: 4%;
    color: #393A3C;
    line-height: 1.6;
}

.spacing-content-container {
    width: 40%;
    min-width: 320px;
    height: fit-content;
    margin-left: 10%;
}

.spacing-content-container p {
    margin-top: 0;
    margin-bottom: 0;
}

.rectangle-32px {
    position: relative;
    background-color: #E8E4DE;
    width: auto;
    height: 32px;
    border: 1px dashed #C6B9A6;
}

.rectangle-16px {
    position: relative;
    background-color: #E8E4DE;
    width: auto;
    height: 16px;
    border: 1px dashed #C6B9A6;
    margin-bottom: 3px;
}

.opensnas-reg-16px {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    line-height: 16px;
    z-index: -1;
}

.notoserif-bold-28px {
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-size: 28px;
}

.opensans-reg-14px {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 14px;
}

.rectangle-size {
    display: flex;
    margin-bottom: 51px;
    justify-content: space-between;
    align-items: baseline;
}

.rectangle-size p {
    color: #48352B;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-align: center;
    margin-top: 6px;
}

.rectnagle-size-4 {
    background-color: #1F3C3D;
    width: 4px;
    height: 4px;
    margin-left: 1.5px;
}

.rectnagle-size-8 {
    background-color: #1F3C3D;
    width: 8px;
    height: 8px;
}

.rectnagle-size-16 {
    background-color: #1F3C3D;
    width: 16px;
    height: 16px;
}

.rectnagle-size-24 {
    background-color: #1F3C3D;
    width: 24px;
    height: 24px;
}

.rectnagle-size-32 {
    background-color: #1F3C3D;
    width: 32px;
    height: 32px;
}

.rectnagle-size-40 {
    background-color: #1F3C3D;
    width: 40px;
    height: 40px;
}

.rectnagle-size-48 {
    background-color: #1F3C3D;
    width: 48px;
    height: 48px;
}

.rectnagle-size-56 {
    background-color: #1F3C3D;
    width: 56px;
    height: 56px;
}

.rectnagle-size-64 {
    background-color: #1F3C3D;
    width: 64px;
    height: 64px;
}

.button-apply {
    background-color: #1F3C3D;
    width: 201px;
    height: 40px;
    color: #FFFFFF;
    border-radius: 6px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-align: center;
    line-height: 40px;
}

@media only screen and (max-width: 1000px) {
    .spacing-background {
        margin-left: 0;
        margin-top: 50px;
    }

    .spacing-content {
        width: 100%;
    }    

    .spacing-elem {
        margin-left: 5%;
    }

    .spacing-content-container {
        margin-left: 0;
        width: 100%;
    }

}