.Typography {
    display: flex;
    width: 100%;
}

.Typography-background {
    background-color: #F4F2EF;
    width: 100%;
    max-width: 1560px;
    /* height: fit-content; */
}

.titlebottomborder {
    width: 217px;
    height: 6px;
    margin-left: 0;
    border-radius: 0px 70px 70px 0px;
    background-color: #1F3C3D;
}

.Typography-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.Typography-elem {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5%;
    margin-left: 9%;
    margin-bottom: 5%;
    margin-right: 5%;
}

.Typographycontent-1 {
    width: 30%;
    height: inherit;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    margin-right: 0px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #393A3C;
    line-height: 1.6;
}

.Typographycontainer-2 {
    width: 60%;
    max-width: 814px;
    height: inherit;
    margin-left: 5%;
    background-color: #EDE9E4;
    display: flex;
    flex-direction: column;
}

.Typographyrow-1 {
    display: flex;
    margin-bottom: 3%;
    margin-top: 3%;
}

.Typographyrow-2 {
    display: flex;
    margin-bottom: 3%;
}

.Typographyrow-3 {
    display: flex;
    margin-bottom: 3%;
}

.Typographyrow-4 {
    display: flex;
    margin-bottom: 3%;
}

.Typographyrow-5 {
    display: flex;
    margin-bottom: 3%;
}

.Typography h2 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #48352B;
}

.Typographycontainer-2 h6 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    color: #48352B;
    text-align: right;
}

.Typographycolumn-2 {
    width: 80%;
    margin-left: 33px;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.col-1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.col-2 {
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-left: 5%;
}

.col-2 h1{
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    color: #1F3C3D;
    font-family: 'Noto Serif', serif;
    font-size: 38px;
    width: 80%;
    height: fit-content;
}

.col-2 h2{
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    width: 80%;
    height: fit-content;
    color: #1F3C3D;
    font-family: 'Noto Serif', serif;
    font-size: 28px;
    align-items: flex-start !;
}

.col-2 h3{
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    width: inherit;
    height: fit-content;
    color: #1F3C3D;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    font-size: 20px;
    font-weight: 700;
}

.col-2 h4{
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    width: inherit;
    height: fit-content;
    color: #1F3C3D;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.col-2 h5{
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    width: inherit;
    height: fit-content;
    color: #1F3C3D;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 2;
    margin-top: 0;
}

@media only screen and (max-width: 1000px) {
    .Typography-background {
        margin-left: 0;
        margin-top: 50px;
    }

    .Typographycontent-1 {
        width: 100%;
    }

    .Typography-elem {
        margin-left: 5%;
    }

    .Typographycontainer-2 {
        margin-left: 0;
        width: fit-content;
    }
}