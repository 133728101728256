p {
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.App {

    display: flex;
    position: relative;
    width: 100%;
    margin: 0 auto;
    /* height: 100vh; */
    overflow: scroll;

}