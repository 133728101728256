.RadioToggleButton {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.RadioToggleButton-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.RadioToggleButton-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.RadioToggleButton-elem {
    margin-left: 5%;
    padding-top: 3%;
    margin-right: 5%;
    margin-bottom: 5%;
}

.RadioToggleButton-container h5 {
    color: #1F3C3D;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 2%;
}

.radio-button-img-helper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-button-img-helper {
    width: 58px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-button-img-helper-2{
    width: 58px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RadioToggleButton-container p {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 32px;
}

.selected-img {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}

.unselected-img {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}

.selected {
    width: 50%;
}

.unselected {
    width: 50%;
}

.second {
    width: 100%;
    height: fit-content;
    background-color: #FFFFFF;
    margin-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
}

.img-text{
    display: flex;
    flex-direction: column;
    height: 100px;
    align-items: center;
    justify-content: space-evenly;
}

.img-text p {
    margin-bottom: 5px;
}

.unselected h4 {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-left: 4%;
}

.selected h4 {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-left: 4%;
}

.circle-hover {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #f3f2f7;
}

.circle-hover img {
    margin-top: 10px;
    margin-left: 10px;
}

.circle-focused {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #EBEAEE;
}

.circle-focused img {
    margin-top: 10px;
    margin-left: 10px;
}

.circle-pressed {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #e8e7eb;
}

.circle-pressed img {
    margin-top: 10px;
    margin-left: 10px;
}









@media only screen and (max-width: 1000px) {
    .RadioToggleButton-background {
        margin-left: 0;
    }

    .second { 
        display: grid;
    }

    .selected {
        width: 100%;
    }
    
    .unselected {
        width: 100%;
    }

    .selected-img {
        width: 100%;
    }
    
    .unselected-img {
        width: 100%;
    }

}