.Stepper {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.Stepper-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.Stepper-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.Stepper-elem {
    margin-top: 2%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
}

.Stepper-img {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 18px;
    margin-bottom: 28px;
}

.Stepper-text p{
    width: 100%;
    margin-top: 8px;
}

.Stepper-text h4 {
    margin-bottom: 0;
}

.types-step {
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 1286px;
    width: 100%;
    height: auto;

}

.stepper-1 {
    max-width: 615px;
    width: 100%;
    height: auto;
}

.stepper-2 {
    margin-left: 5%;
    max-width: 465px;
    width: 100%;
    height: auto;
}

.alert {
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 499px;
    width: 100%;
    height: auto;
}

.mobile {
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 324px;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1000px) {
    .Stepper-background {
        margin-left: 0;
    }



}