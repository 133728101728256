.Tabs {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.Tabs-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.Tabs-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.Tabs-elem {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
}

.Tabs-text p {
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin-bottom: 26px;
}

.first-img img:last-child{
    margin-bottom: 18px;
    /* mix-blend-mode: darken; */
}

.tabs-tabs-img {
    max-width: 737px;
    width: 100%;
    height: auto;
}

.tabs-top {
    max-width: 375px;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1000px) {
    .Tabs-background {
        margin-left: 0;
    }

}