.thelogo {
    display: flex;
    width: 100%;
    scroll-margin: 600px;
}

.thelogobackground h1 {
    margin-top: 3%;
    margin-left: 3%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.contentcontainer {
    display: flex;
    flex-flow: wrap column;
    height: fit-content;
    width: 90%;
    margin: 3% 6% 6% 6% ;
    /* justify-content: space-between; */
}

.contentcontainer h1 {
    margin-top: 0%;
    margin-left: 0%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.titlebottomborder {
    width: 217px;
    height: 6px;
    margin-left: 0;
    border-radius: 0px 70px 70px 0px;
    background-color: #1F3C3D;
}

.container-for-1-2 {
    display: flex;
    flex-wrap: wrap;
    /* width: 50%; */
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5%;
    height: auto;
}

.container-for-3-4 {
    display: flex;
    flex-wrap: wrap;
    /* width: 50%; */
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-top: 0%;
}

.contentcontainer-1 {
    width: 35%;
    min-height: 178px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contentcontainer-1 > p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
}

.contentcontainer-2 {
    margin-left:0;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.container-2-grid h2 {
    width: 84px;
    height: 22px;
    font-size: 16px;
    margin-left: 91px;
    margin-bottom: 10px;
    
}

.logogrid {
    margin-top: 0;
    width: 100%;
    max-width: 266px;
    height: auto;
}

.logogroup {
    width: 100%;
    max-width: 347px;
    height: auto;
}

.contentcontainer-3 {
    width: 35%;
    min-height: 178px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contentcontainer-3 p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
}

.contentcontainer-4 {
    width: 50%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}


.first-text {
    font-size: 14px;
    color: #48352B;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.max-sizing {
    width: 30%;
    min-height: 319px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.min-sizing {
    width: 30%;
    min-height: 319px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.secondsizing h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.secondsizing h6:first-child{
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.secondsizing h6:last-child{
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.thirdsizing h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.secondsizing img {
    margin-top: 11px;
    margin-bottom: 19.7px;
}

.thirdsizing img {
    margin-top: 12.5px;
    margin-bottom: 8.5px;
}

.thirdsizing h6:first-child{
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.thirdsizing h6:last-child{
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

@media only screen and (max-width: 1000px) {
    .thelogobackground {
        margin-left: 0;
    }

    .container-for-1-2  {
        display: grid;
    }

    .contentcontainer-2 {
        width: 100%;
        justify-content: center;
        margin-top: 10%;
    }

    .contentcontainer-1 {
        width: 100%;
    }

    .container-2-grid {
        margin-top: 5%;
    }

    .container-for-3-4  {
        display: grid;
    }

    .contentcontainer-4 {
        width: 100%;
        justify-content: space-around;
        margin-top: 10%;
    }

    .contentcontainer-3 {
        width: 100%;
    }

}
