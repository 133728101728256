.DataTable {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.DataTable-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.DataTable-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.DataTable-elem {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
}

.DataTable-elem p {
    margin-top: 8%;
    color: #397DF3;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.DataTable-first {
    max-width: 1046px;
    width: 100%;
    height: auto;
}

.DataTable-second {
    max-width: 1046px;
    width: 100%;
    height: auto;
    margin-top: 5%;
}

.DataTable-third {
    max-width: 1046px;
    width: 100%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 5%;
}


@media only screen and (max-width: 1000px) {
    .DataTable-background {
        margin-left: 0;
    }

}