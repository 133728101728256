.Checkboxes {
    display: flex;
    width: 100%;
    scroll-margin: 220px; 
}

.Checkboxes-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.Checkboxes-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.checkboxes-elem {
    margin-left: 5%;
    padding-top: 3%;
    margin-right: 5%;
}

.checkboxes-first h4 {
    color: #1f3c3d;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1%;
}

.checkboxes-first h5 {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 0;
}

.checkboxes-first p {
    color: #393A3C;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    margin-top: 2px;
}

.checkboxes-second {
    width: 100%;
    margin-top: 3%;
    height: fit-content;
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

.checkboxes-selected {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3%;
}


.checkboxes-unselected{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3%;
}

.checkboxes-intermediate {
    margin: 3%;
    width: 100%;
}


.checkboxes-selected h4 {
    margin-top: 0;
}

.checkboxes-unselected h4 {
    margin-top: 0;
}

.checkboxes-intermediate h4 {
    margin-top: 0;
}

.align-img-helper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}





@media only screen and (max-width: 1000px) {
    .Checkboxes-background {
        margin-left: 0;
    }

    .checkboxes-second {
        display: flex;
        flex-wrap: wrap;
    }

}