.BottomSheet {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.BottomSheet-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.BottomSheet-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: auto;
    height: 49px;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.BottomSheet-elem {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
}

.BottomSheet-elem p {
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin-bottom: 40px;
}

.BottomSheet-img{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.BottomSheet-first {
    max-width: 368px;
    width: 100%;
    height: auto;
}

.BottomSheet-second {
    max-width: 478px;
    width: 100%;
    height: auto;
}

.BottomSheet-img  img:last-child{
    /* margin-left: 20%; */
}

@media only screen and (max-width: 1000px) {
    .BottomSheet-background {
        margin-left: 0;
    }

    .BottomSheet-second {
        margin-top: 20%;
    }

}
