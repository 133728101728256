.Snackbars {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.Snackbars-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.Snackbars-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.Snackbars-elem {
    margin-top: 2%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
}

.snackbar-main {
    max-width: 717px;
    width: 100%;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}

.snackbar-1 {
    max-width: 476px;
    width: 100%;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}

.snackbar-2 {
    max-width: 522px;
    width: 100%;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
}

.Snackbars-text p {
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin-bottom: 14px;
}

.Snackbars-text h4 {
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 0;
}

@media only screen and (max-width: 1000px) {
    .Snackbars-background {
        margin-left: 0;
    }

    .snackbar-2 {
        margin-left: 0;
    }

}