.Iconography {
    display: flex;
    width: 100%;
    height: fit-content;
}

.Iconography-background {
    background-color: #F4F2EF;
    width: 100%;
}

.Iconography-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.icon-size {
    margin-left: 30%;
    max-width: 553px;
    width: 100%;
    height: auto;
}

.Iconography-elem {
    margin-top: 3%;
    margin-left: 9%;
    margin-bottom: 5%;
    margin-right: 5%;
    width: fit-content;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
}

.Iconography-elem p {
    width: fit-content;
}

.line-icon {
    width: fit-content;
    height: auto;
}

.filled-icon {
    width: 100%;
    height: auto;   
}

.filled-icon img {
    margin: 0 3% 2% 0;
    width: 24px;
    height: 24px;
}

.line-icon img {
    margin: 0 3% 2% 0;
    width: 24px;
    height: 24px;
}

.channel-icon {
    width: 100%;
    height: auto;
}

.channel-icon img {
    margin: 0 3% 2% 0;
    width: 24px;
    height: 24px;
}

.status-icon {
    max-width: 1266px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    background-color: #FFFFFF;
}

.status {
    margin: 1%;
    width: 80px;
    height: 30px;
    background-color: #FDF7E9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 1px;
}

.lead {
    width: 81px;
}

.meetingdone {
    width: 143px;
}

.visitdone {
    width: 120px;
}

.revisitdone {
    width: 148px;
}

.bookingdone {
    width: 148px;
}

.cancelled {
    width: 108px;
}

.invited {
    width: 91px;
}

.approval-pending {
    width: 155px;
}

.rejected {
    width: 104px;
}

.self-registered {
    width: 136px;
}

.app-downloaded {
    width: 153px;
}

.rejected {
    width: 104px;
}






@media only screen and (max-width: 1000px) {
    .Iconography-background {
        margin-left: 0;
        margin-top: 50px;
    }

    .icon-size {
        max-width: 300px;
        object-fit: contain;
        margin-left: 10%;
    }

    .Iconography-elem {
        margin-left: 5%;
    }

    .status {
        margin: 5%; 
    }

    .line-icon img {
        margin: 4.5%;
    }

    .filled-icon img {
        margin: 5%;
    }

    .channel-icon img {
        margin: 5%;
    }
}