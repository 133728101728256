.Layoutandgrids {
    display: flex;
    width: 100%;
}

.Layoutandgrids-background {
    background-color: #F4F2EF;
    width: 100%;
    /* height: fit-content; */
}

.Layoutandgrids-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.layoutandgrids-elem{
    min-height: 366px;
    height: fit-content;
    width: 90%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 5%;
    margin-left: 9%;
}

.layoutandgrids-box {
    height: 303px;
    max-width: 400px;
    width: 100%;
    margin-right: 3%;
    margin-bottom: 5%;
    background-color: #EDE9E4;
    display: grid;
}

.layoutandgrids-box p {
    margin-top: 9px;
    margin-bottom: 27px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: #48352B;
    font-size: 14px;
}

.layoutandgrids-box h4 {
    margin-bottom: 0;
    margin-top: 17px;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}

.layoutandgrids-mobile-img {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.layoutandgrids-tablet-img {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.layoutandgrids-desktop-img {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.layoutandgrids-tablet-text {
    height: 82px;
    margin-left: 52px;
}

.layoutandgrids-desktop-text {
    height: 82px;
    margin-left: 52px;
}

.layoutandgrids-mobile-text {
    height: 82px;
    margin-left: 52px;
}

@media only screen and (max-width: 1000px) {
    .Layoutandgrids-background {
        margin-left: 0;
        margin-top: 50px;
    }

    .layoutandgrids-elem {
        margin-left: 5%;
    }

}