.home {
    display: flex;
    width: 100%;
}

.homebackground {
    background-image: url('../Assets/homebackground.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    min-height: 1080px;
    height: 100%;
}

.brandguidelines {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    margin-left: 5%;
    max-width: 520px;
    height: auto;
    opacity: 1;
}

.brand-text > h2{
    width: 100%;
    height: auto;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 95px;
    letter-spacing: 0px;
    color: #1F3C3D;
    opacity: 1;
    padding-top: 5%;
}

.welove-text >h2 {
    /* text-align: left; */
    font: normal normal normal 20px/26px Open Sans;
    letter-spacing: 0px;
    color: #1F3C3D;
    opacity: 1;
    padding-left: 2%;
    padding-top: 5%;
}

.heart-icon{
    width: 16px;
    height: 15px;
    color: #000000;
}

h2 {
    margin-top: 0;
    margin-bottom: 0;
}

.brandguidelines img {
    width: 200px;
    height: auto;
}


@media only screen and (max-width: 1000px) {
    .homebackground {
        margin-left: 0;
        background-image: url('../Assets/background-mobile.png');
        height: 100%;
    }

    .home {
        display: flex;
        width: 100%;
    }

    .brandguidelines {
        margin-top: 25%;
    }

    .brand-text > h2 {
        font-size: 55px;
    }
}