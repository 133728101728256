.thelogobackground {
    width: 80%;
    overflow: scroll;
    position: relative;
    background-color: #F4F2EF;
}


@media only screen and (max-width: 1000px) {
    .thefincitylogobackground {
        margin-left: 0;
        margin-top: 50px;
    }

    .thelogobackground {
        width: 100%;
    }

    .StickyNav {
        width: 100%;
    }

}




