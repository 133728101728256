.faq-elems {
    display: flex;
    flex-wrap: wrap;
    margin: 5%;
    justify-content: space-between;
}

.faq-elems h1 {
    font-size: 22px;
    margin-top: 0;
    margin-left: 0;
}

.faq-desktop {
    overflow: scroll;
}
