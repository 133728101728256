.navDropdown {
  height: fit-content;
  width: fit-content;
  max-width: 1250px;
  display: flex;
  margin-top: 2%;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 1%;
  display: flex;
  flex-wrap: wrap;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.navDropdown ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.navDropdown-button {
  height: 40px;
  display: flex;
  width: fit-content;
  border: 1px solid #1f3c3d;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  padding: 0px 8px 0px 8px ;
  margin: 8px;
  font-size: 16px;
  color: #1f3c3d;
}

.navDropdown-button:hover {
  cursor: pointer;
  background-color: #1f3c3d;
  color: white;
}

.navDropdown ul .thelogo {
  width: auto;
}

.navDropdown ul .UsesOnBackground {
  width: auto;
}
.navDropdown ul .LogoMisuse {
  width: auto;
}
.navDropdown ul .Buttons {
  width: auto;
  margin: 0;
}


.navDropdown ul .FloatingActionButton {
  width: auto;
}
.navDropdown ul .RadioToggleButton {
  width: auto;
}
.navDropdown ul .Chips {
  width: auto;
}
.navDropdown ul .Stepper {
  width: auto;
}
.navDropdown ul .Snackbars {
  width: auto;
}
.navDropdown ul .Tabs {
  width: auto;
}
.navDropdown ul .Checkboxes {
  width: auto;
}
.navDropdown ul .Dialogbox {
  width: auto;
}
.navDropdown ul .BottomSheet {
  width: auto;
}
.navDropdown ul .Cards {
  width: auto;
}
.navDropdown ul .DateTimePicker {
  width: auto;
}
.navDropdown ul .TextFieldDropdown {
  width: auto;
}
.navDropdown ul .DataTable {
  width: auto;
}
.navDropdown ul .phoneNumberBackground {
  width: auto;
  margin: 0;
  height: auto;
}
.navDropdown ul .Others {
  width: auto;
}

.navDropdown ul li.active a {
  background-color: #1f3c3d;
  color: white;
  font-weight: normal;
}

.navDropdown ul li a {
  background-color: white;
  color: #1f3c3d;
  font-weight: normal;
}


@media only screen and (max-width: 1000px) { 
    .navDropdown {
      flex-wrap: nowrap;
      overflow: scroll;
      width: 94%;
    }

    .navDropdown ul {
      flex-wrap: nowrap;
      overflow: scroll;
    }    

    .navDropdown-button {
      width: max-content;
    }

    .navDropdown-button:hover {
      background-color: unset;
      color: unset;
    }
}


