.phoneNumberBackground {
    margin: 5%;
    /* height: 500px; */
    scroll-margin: 225px;
}

.phoneNumberBackground h1 {
    margin-left: 0;
    font-size: 20px;
}

.phoneNumberelems {
    padding-top: 4%;
    /* display: grid; */
}

.phoneNumberelems-img {
    margin-right: 10%;
}

.phoneNumber-helper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.phoneNumberText {
    margin-top: 20px;
    margin-bottom: 20px;
}

.phoneNumberelems-otp {
    padding-top: 20px;
}

.phoneNumberelems-field {
    padding-bottom: 20px;
}

.phoneNumberText p {
    margin: 4px;

}

.phoneNumberBackground img {
    margin-top: 20px;
}

.phoneNumberBackground h6 {
    font-size: 12px;
    margin-top: 2px;
    width: 217px;
    color: #393A3C;
    font-weight: normal;

}

@media only screen and (max-width: 1000px) {
    .phoneNumberelems-img {
        margin-right: 0%;
    }
}