.DateTimePicker {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.DateTimePicker-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.DateTimePicker-elem {
    padding-top: 2%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
}

.date-picker h4{
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
}

.date-picker {
    margin-bottom: 78px;
}

.datepicker-img {
    max-width: 525px;
    width: 100%;
    height: auto;
}

.date-spec-1 {
    max-width: 340px;
    width: 100%;
    height: auto;
}

.date-spec-2 {
    max-width: 340px;
    width: 100%;
    height: auto;
}

.date-spec-3 {
    max-width: 352px;
    width: 100%;
    height: auto;
}

.date-picker p {
    margin-top: 14px;
}

.DateTimePicker-elem h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #393A3C;
}

.time-picker h4 {
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 89px;
}

.date-specs {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.date-specs img {
    margin-right: 24px;
}

.time-specs {
    display: grid;
}

.time-specs-2-3 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 55px;
}

.time-specs-2-3 img {
    margin-right: 41px;
}

.time-spec-1 {
    max-width: 499px;
    width: 100%;
    height: auto;
}

.time-spec-2 {
    max-width: 307px;
    width: 100%;
    height: auto;
}

.time-spec-3 {
    max-width: 363px;
    width: 100%;
    height: auto;
}



@media only screen and (max-width: 1000px) {
    .DateTimePicker-background {
        margin-left: 0;
    }

    .date-spec-2 {
        margin-top: 5%;
    }
    .date-spec-3 {
        margin-top: 5%;
    }

    .time-spec-3 {
        margin-top: 10%;
    }

}