.Buttons {
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: #F4F2EF;
    margin-right: 6%;
    margin-left: 5%;
    scroll-margin: 300px;
}

.buttons-elems {
    padding-top: 5%;
}

.Buttons-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.button-text-1 h4 {
    color: #1F3C3D;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1%;
    margin-top: 0;
}

.button-text-2 h6 {
    color: #393A3C;
    font-family: Open Sans;
    font-weight: bold;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
}

.button-text-img {
    background-color: #FFFFFF;
    width: 100%;
    /* max-width: 350px; */
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    /* justify-content: space-between; */
}

.button-text-img * {
    margin: 1% 2% 1% 2%;
}

.button-inside-text-img {
    max-width: 350px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0 0;
    justify-content: space-between;
}

.button-inside-1 p {
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
    width: fit-content;
}

.button-inside-text-img h5 {
    margin-left: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #000000;
    font-family: Open Sans;
}

.button-div-elevated {
    max-width: 108px;
    width: 100%;
    height: 41px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 16px #0000007B;
    border-radius: 4px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #1F3C3D;
}

.button-div-filled {
    max-width: 108px;
    width: 100%;
    height: 41px;
    background-color: #1F3C3D;
    border-radius: 4px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #FFFFFF;
}

.button-div-outlined {
    max-width: 108px;
    width: 100%;
    height: 41px;
    background-color: #FFFFFF;
    border: 1px solid #1F3C3D;
    border-radius: 4px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #1F3C3D;
}

.button-div-text {
    max-width: 108px;
    width: 100%;
    height: 41px;
    background-color: #FFFFFF;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #1F3C3D;
}

.button-col {
    width: 20%;
    min-width: 150px;
    height: 600px;
}

.button-row {
    height: 100px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-row p {
    color: #1F3C3D;
    font-size: 14px;
    font-weight: bold;
}

.white {
    background-color: #FFFFFF;
}

.button-img-container {
    width: 100%;
    margin-top: 5%;
    overflow-x: scroll;
    /* min-width: 600px; */
    display: grid;
}

.default {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    /* overflow-x: scroll; */
    /* margin: 0 5%; */
}

.with-icon {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    /* overflow-x: scroll; */
    /* margin: 0 5%; */
}

.default-text {
    width: fit-content;
}

.button-disabled {
    background-color: #C7C8D6;
    color: #1F3C3D;
}

.button-hover {
    background-color: #1F3C3D;
}

.button-pressed {
    background-color: #102829;
    opacity: 0.69;
}

.outlined-spinner {
    background-color: #102829;
}

.default-outlined-disabled {
    border: 1px solid #C7C8D6;
    color: #6E6E6E;
}

.default-outlined-hover {
    border: 1px solid #1F3C3D;
    background-color: #1F3C3D1C;
    color: #6E6E6E;
}

.default-outlined-pressed {
    border: 1px solid #1F3C3D;
    background-color: #1F3C3D45;
    color: #6E6E6E;
}

.button-spinner {
    display: flex;
    justify-content: flex-start;
}

.spinner {
    height: 9px;
    width: 9px;
    border-top: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    border-radius: 50%;
    margin-right: 11px;
    margin-left: 11px;
    transform: rotate(45deg);
}

.default-text-disabled {
    color: #6C7586;
}

.default-text-hover {
    background-color: #1F3C3D14;
}

.default-text-pressed {
    background-color: #1F3C3D29;
}

.default-text-spinner {
}

.buttons-arrow-down {
    width: 30%;
    justify-content: space-around;
}

.button-div-filled hr {
    transform: rotate(-90deg);
    margin: 0;
    height: 1px;
    width: 36px;
    background-color: #FFFFFF1D;
    opacity: 1;
}

.button-div-filled p {
    color: #FFFFFF;
    font-weight: normal;
    font-size: 12px;
    width: 70%;
}

.button-spinner-arrow p {
    width: 30%;
    margin-right: 20%;
}

.buttons-arrow-down {
    border-left: 1px solid #FFFFFF1D;
    height: 100%;
    display: flex;
    align-items: center;
}

.arrow {
    max-width: 145px;
}



@media only screen and (max-width: 1000px) {
    .Buttons-background {
        margin-left: 0;
        margin-right: 0;
    }

    .buttons-elems {
        padding-top: 10%;
    }

}