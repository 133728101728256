.TextFieldDropdown {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.TextFieldDropdown-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.TextFieldDropdown-elem {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
}

.text-field h4 {
    color: #1F3C3D;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1%;
}

.drop-field {
    margin-top: 8%;
}

.drop-field h4 {
    color: #1F3C3D;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 2%;
}

.text-field-content p {
    margin-bottom: 0;
    margin-top: 0;
}

.text-field-label-img-1 *{
    margin-right: 4%;
}

.text-field-label-img-2 {
    margin-top: 2%;
    margin-bottom: 2%;
    display: grid;

}

.text-field-label-img-2 {
    margin-top: 5%;
}

.text-field-label-img-row1{
    display: flex;
    flex-wrap: wrap;
}

.label-text-img {
    width: 230px;
    margin: 1%;
}

.text-field-label-img-row2{
    display: flex;
    flex-wrap: wrap;
}

.text-field-label-img-row3{
    display: flex;
    flex-wrap: wrap;
}

.text-field-label-img-row4{
    display: flex;
    flex-wrap: wrap;
}

.drop-field-img * {
    margin-right: 3%;
}

@media only screen and (max-width: 1000px) {
    .TextFieldDropdown-background {
        margin-left: 0;
    }

    .label-text-img  {
        margin: 5% !important;
    }

}