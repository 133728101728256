.UsesOnBackground {
    display: flex;
    width: 100%;
    /* height: 984px; */
    scroll-margin: 200px;
}

.UsesOnBackground-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.UsesOnBackground-background > h1 {
    margin-bottom: 3%;
    margin-top: 0;
    padding-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.contentcontainer-uses {
    width: 50%;
    min-height: 126px;
    height: fit-content;
    margin-right: 0px;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contentcontainer-uses-img {
    max-width: 479px;
    width: 100%;
    height: auto;
}

.contentcontainer-uses > p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
}

.UsesOnBackground-elem {
    display: flex;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 0%;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media only screen and (max-width: 1000px) {
    .UsesOnBackground-background {
        margin-left: 0;
        /* margin-top: 50px; */
    }

    .contentcontainer-uses-img {
        margin-left: 0 !important;
    }

}