body ::-webkit-scrollbar {
  display: none;
}

.main-bar {
  width: 20%;
  z-index: 3;
  /* flex-grow: 1; */
}

.navbar {
  width: 20%;
  height: inherit;
  background-color: #1f3c3d;
  text-align: center;
  overflow-y: scroll;
  z-index: 2;
  transition: left 0.3s ease;
  position: fixed;
}

.navname {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: transparent;
  opacity: 1;
}

.navelems {
  height: fit-content;
}

.navelem {
  height: fit-content;
  padding-left: 0;
  padding-right: 0;
  font-family: "Open Sans", sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}

.navlinktag {
  display: grid;
  padding: 5% 5% 5% 40px;
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  transition: linear 0.2s;
  letter-spacing: 0px;
  color: #c7c8d6;
  opacity: 1;
  height: fit-content;
  line-height: 22px;
}

hr {
  background-color: #4c5e68;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 0;
  margin-bottom: 0;
  height: 1px;
  border: none;
}

.navlinktag:hover {
  font-weight: bold;
  color: white;
}

.active {
  font-weight: bold;
  color: white;
}

.navdropdown {
  display: flex;
  padding-right: 40px;
  justify-content: space-between;
  background: transparent;
  border: transparent;
  font-size: 16px;
  cursor: pointer;
}

ul {
  list-style: none;
}

.dropdownelem {
  /* animation:reverse; */
  height: fit-content;
  overflow: hidden;
  padding-right: 0;
  padding-bottom: 0;
  background-color: #00000033;
  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top center;
  transition: linear 200ms;
}

.dropdownelemreverse {
  animation: reverse;
  height: 0px;
  overflow: hidden;
  padding-right: 0;
  padding-bottom: 0;
  background-color: #152829;
}

.nav-mobile {
    width: 100%;
    display: flex;
    position: fixed;
    overflow: auto;
    background-color: #1f3c3d;
    z-index: 3;
    /* justify-content: right; */
}

.nav-button {
  display: none;
  background-color: transparent;
  position: fixed;
  margin: 10px 10px 10px 10px;
}

.nav-button hr {
  background-color: #ffffff;
  height: 4px;
  width: 60%;
  margin-top: 5px;
  border-radius: 5px;
  margin-left: 20%;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@media only screen and (max-width: 1000px) {
  .main-bar {
    width: 0px;
  }

  .nav-mobile{
    height: 50px;
    width: 100%;
  }

  .navbar {
    margin-top: 50px;
    position: fixed;
  }

  .nav-button {
    display: flex;
    position: absolute;
    flex-direction: column;
    z-index: 2;
  }
}
