.Dialogbox {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.Dialogbox-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.Dialogbox-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.Dialogbox-elem {
    margin-top: 2%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    display: grid;
}

.Dialogbox-second {
    grid-column: 2;
    margin-left: 10%;
}

.Dialogbox-third {
    margin-top: 8%;
}

.Dialogbox-text {
    color: #393A3C;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.Dialogbox-first{
    width: 70%;
    display: flex;
    flex-direction: column;
}

.basic-full {
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 700px;
    width: 100%;
    height: auto;
}

.full {
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 400px;
    width: 100%;
    height: auto;
}

.basic {
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 350px;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1000px) {
    .Dialogbox-background {
        margin-left: 0;
    }

    .Dialogbox-second {
        grid-column: 1;
        margin-left: 0;
    }

    .Dialogbox-first{
        width: 100%;
    }

}