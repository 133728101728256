.Others {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.Others-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
}

.Others-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
}

.others-elem {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 10%;
    margin-right: 5%;
}

.blue {
    color: #397EF5;
    font-weight: bold;
} 
.others-elem p {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.others-elem h4 {
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 14px;
}

.pagination {
    max-width: 365px;
    width: 100%;
    height: auto;
    mix-blend-mode: darken;
    margin-top: 14px;
}

.slider-1 {
    max-width: 423px;
    width: 100%;
    height: auto;
}

.slider-2 {
    max-width: 611px;
    width: 100%;
    height: auto;
    margin-left: 20%;
}

.others-breadcrumbs-img {
    margin-top: 28px;
    margin-bottom: 60px;
}

.others-breadcrumbs-img p {
    display: inline;
}

.others-links-img {
    margin-top: 26px;
    margin-bottom: 60px;
}

.others-links-img p {
    text-decoration: underline;
}

.loading {
    max-width: 167px;
    width: 100%;
    height: auto;
    mix-blend-mode: darken;
    margin-top: 19px;
    margin-bottom: 31px;
}

.search {
    max-width: 340px;
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.others-slider-img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 31px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1000px) {
    .Others-background {
        margin-left: 0;
    }

    .slider-2 {
        margin-top: 10%;
        margin-left: 0;
    }

}