.StickyNav {
    position: fixed;
    background-color: #F4F2EF;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 2;
    top: 0px;
    /* padding-top: 50px; */
    width: 80%;
}

.StickyNav h1 {
    margin-top: 0;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 36px;
    /* padding-top: 50px; */
}

@media only screen and (max-width: 1000px) {
    .thecomponentsbackground {
        margin-left: 0;
        margin-top: 50px;
    }
    .StickyNav {
        top: 50px;
        width: 100%;
    }

}

