.Chips {
    display: flex;
    width: 100%;
    scroll-margin: 220px;
}

.Chips-background {
    background-color: #F4F2EF;
    width: 100%;
    height: fit-content;
    margin-top: 5%;
}

.Chips-background h1 {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.chips-elem {
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    display: flex;
    /* align-self: auto; */
    flex-wrap: wrap;
}

.chips-first {
    width: 50%;
}


.chips-second img {
    mix-blend-mode: darken;
    /* margin-left: 56px; */
}

.chips-first-text {
    width: 70%;
}

.chips-first-text h4 {
    color: #393A3C;
}

.chips-en-dis img {
    margin-right: 2%;
    margin-bottom: 2%;
}

.chips-button img {
    margin-right: 5%;
    margin-bottom: 10%;
}

.chips-img {
    margin-top: 15%;
}

.chips-second img {
    width: auto;
    height: auto;
    max-width: 591px;
    max-height: 435px;
}

@media only screen and (max-width: 1000px) {
    .Chips-background {
        margin-left: 0;
    }

    .chips-first {
        width: 100%;
    }

    .chips-second img {
        margin-top: 5%;
        width: 320px;
        object-fit: contain;
    }

    .chips-first-text {
        width: 100%;
    }

}