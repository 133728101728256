.LogoMisuse {
    display: flex;
    width: 100%;
    /* height: 984px; */
    scroll-margin: 150px;
}

.LogoMisuse-background {
    background-color: #F4F2EF;
    width: 100%;
    height: 984px;
    height: fit-content;
    margin: 5% !important;
    /* height: fit-content; */
}

.section-height-logomisuse {
    min-height: 984px;
}

.LogoMisuse-background h1 {
    margin-bottom: 3%;
    margin-left: 0%;
    width: fit-content;
    height: fit-content;
    color: #1f3c3d;
    text-align: left;
    font-family: 'Noto Serif', serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.LogoMisuse-elem {
    height: fit-content;
    /* margin-right: 4%; */
    display: flex;
    justify-content: space-between;
}

.firstcontainer {
    height: fit-content;
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.secondcontainer {
    height: fit-content;
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.column-1 {
    height: fit-content;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #EDE9E4;
}

.FincityLogo-5 img:first-child {
    margin-bottom: 38px;
}

.FincityLogo-5 img:last-child{
    margin-left: 9px;
}

.column-2{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.column-2 img {
    margin-top: 23px;
    margin-bottom: 23px;
}

.column-1 p {
    margin-left: 5%;
    margin-top: 10%;
    margin-bottom: 15%;
    margin-right: 10%;
    font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 1000px) {
    .LogoMisuse-background {
        margin-left: 0;
    }

    .LogoMisuse {
        scroll-margin: 200px;
    }

    .LogoMisuse-elem {
        flex-direction: column;
    }

    .firstcontainer {
        width: 100%;
        margin-bottom: 10%;
    }

    .secondcontainer {
        width: 100%;

    }

}
